import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image"
import styled from "styled-components";
import moment from "moment";

import SEO from "../components/seo";
import Header from "../components/header";
import Footer from "../components/footer";
import Social from "../components/social";

require("../../prismjs/themes/prismjs-solarizedlight2.css");

const Post = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const PostMain = styled.div`
  background: #ffffff;
  padding-bottom: 4vw;
  z-index: 100;
  flex-grow: 1;
  position: relative;
  padding: 0 4vw;
`

const PostColumn = styled.div`
  margin: 0 auto;
  max-width: 1040px;
  width: 100%;
`

const Article = styled.article`
  position: relative;
  z-index: 50;
`

const ArticleHeader = styled.header`
  margin: 0 auto;
  padding: 6vw 3vw 3vw;
  max-width: 1040px;
  text-align: center;
  
  @media (max-width: 500px) {
    padding: 14vw 3vw 10vw;
  }

  section {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #738a94;
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;

    @media (max-width: 500px) {
      font-size: 1.0rem;
      line-height: 1.3em;
    }
  }

  time {
    color: #3eb0ef;
    font-family: serif;
  }

  .divider {
    display: inline-block;
    margin: 0 6px 1px;
  }

  a {
    color: rgb(38, 166, 237);
    text-decoration: none;
  }

  h1 {
    font-weight: 700;
    font-size: 3rem;
  }
`

const ArticleFigure = styled.figure`
  height: 800px;
  margin: 0px -10vw -165px;
  background: center center / cover rgb(197, 210, 217);
  border-radius: 5px;

  @media (max-width: 1170px) {
    height: 600px;
    margin: 0px -4vw -100px;
    border-radius: 0px;
  }

  @media (max-width: 800px) {
    height: 400px;
  }

  @media (max-width: 500px) {
    margin-bottom: 4vw;
    height: 350px;
  }
`

const ArticleContent = styled.section`
  position: relative;
  min-height: 230px;
  font-family: Georgia, serif;
  font-size: 1.2rem;
  margin: 0px auto;
  padding: 70px 100px 40px;
  background: rgb(255, 255, 255);

  @media (max-width: 1170px) {
    padding: 5vw 7vw 40px;
  }

  @media (max-width: 800px) {
    font-size: 1.1rem;
  }

  @media (max-width: 500px) {
    padding: 0px;
  }

  @media (min-width: 500px) {
    &:before {
      content: "";
      position: absolute;
      top: 15px;
      left: -5px;
      z-index: -1;
      display: block;
      width: 20px;
      height: 200px;
      filter: blur(5px);
      transform: rotate(-5deg);
      background: rgba(39, 44, 49, 0.15);
    }

    &:after {
      content: "";
      position: absolute;
      top: 15px;
      right: -5px;
      z-index: -1;
      display: block;
      width: 20px;
      height: 200px;
      filter: blur(5px);
      transform: rotate(5deg);
      background: rgba(39, 44, 49, 0.15);
    }
  }
`

const SignOff = (props) => {
  return(
    <div>
      <p>
        -- Richard, {shortDate(props.date)}
      </p>
    </div>
  )
}

const humanDate = (str) => {
  var m = moment.utc(str);
  return m.format("DD MMMM YYYY");
}

const shortDate = (str) => {
  var m = moment.utc(str);
  return m.format("MMM YYYY");
}

const BlogPost = ({ data }) => {
  const { title, createdAt, synopsis, body, image, tags, slug } = data.contentfulBlogPost;
  return (
    <Post>
      <SEO title={title} description={synopsis.synopsis} image={"https:" + image.fluid.src} />
      <Header small />
      <PostMain>
        <PostColumn>
          <Article>
            <ArticleHeader>
              <section>
                <time>{humanDate(createdAt)}</time>
                <span className="divider">/</span>
                <a href={"/tag/" + tags[0]}>{tags[0]}</a>
              </section>
              <h1>{title}</h1>
            </ArticleHeader>
            <ArticleFigure>
              <Img style={{height: "100%", width: "100%", position: "relative", overflow: "hidden"}} fluid={image.fluid}/>
            </ArticleFigure>
            <ArticleContent>
              <div dangerouslySetInnerHTML={{ __html: body.childMarkdownRemark.html}}></div>
              <SignOff date={createdAt} />
              <Social url={"https://rjk.codes/post/" + slug + "/"} title={title} />
            </ArticleContent>
          </Article>
        </PostColumn>
      </PostMain>
      <Footer/>
    </Post>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      createdAt
      body {
        childMarkdownRemark {
          html
        }
      }
      synopsis {
        synopsis
      }
      image {
        fluid(maxWidth: 1000){
          ...GatsbyContentfulFluid_withWebp
        }
      }
      tags
    }
  }
`;